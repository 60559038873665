import React from 'react';
import { string, oneOfType, node, func } from 'prop-types';
import { Label } from '../../prop-types';
import Media from '../media';

const BoxListItem = ({ className, label, title, figure }) => {
  const content = label || title;

  return (
    content && (
      <li key={content.text}>
        <Media className={className} title={content} figure={figure} />
      </li>
    )
  );
};

BoxListItem.propTypes = {
  className: string,
  label: Label,
  title: Label,
  figure: oneOfType([node, func]),
};

export default BoxListItem;
