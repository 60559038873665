import React from 'react';
import { string, shape } from 'prop-types';
import StyledLabel from '../../../styled-label';

const namespace = 'ui-pdp-qadb__no-last-questions';

const NoLastQuestions = ({ title, subtitle }) => (
  <div className={namespace}>
    <StyledLabel className="no-last-questions__title" text={title.text} />
    <StyledLabel className="no-last-questions__subtitle" text={subtitle.text} />
  </div>
);

NoLastQuestions.propTypes = {
  title: shape({
    text: string.isRequired,
  }).isRequired,
  subtitle: shape({
    text: string,
  }),
};

NoLastQuestions.defaultProps = {
  subtitle: {
    text: null,
  },
};

export default NoLastQuestions;
