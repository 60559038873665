import React, { useState, useContext } from 'react';
import { shape, bool, arrayOf, string, func } from 'prop-types';
import classnames from 'classnames';
import StaticPropsContext from '../context/static-props';
import Snackbar from './components/snackbar';
import useTrackView from '../../hooks/use-track-view';
import { trackEvent } from '../../lib/tracking';
import {
  QUESTIONS,
  OWNER_QUESTIONS,
  NO_LAST_QUESTIONS,
  FEEDBACK,
  MAKE_QUESTION_MODAL,
  SMART_ANSWER_MODAL,
  MAKE_QUESTION,
  MAKE_QUESTION_SA,
  CARD_ANSWER,
  RESULTS,
  CALL_TO_ACTION,
  PUSH_NOTIFICATION_QUESTION_MODAL,
} from './qadb.constants';
import SearchBar from './components/search-bar';
import QuestionsQuickAccess from './components/questions-quick-access';
import QuestionsList from './components/questions-list';
import NoLastQuestions from './components/no-last-questions';
import QuestionModal from './components/question-modal';
import MakeQuestion from './components/make-question';
import MakeQuestionSA from './components/make-question-sa';
import SmartAnswerModal from './components/smart-answer-modal';
import CardAnswer from './components/card-answer';
import Feedback from './components/feedback';
import HowToMakeQuestionButton from './components/how-to-make-question-button';
import QuestionWarningMessage from '../message/question-warning';
import OnBoardingMobile from '../onboarding/onboarding.mobile';

const Loader = () => <div className="ui-pdp-fetching-component" />;

const namespace = 'ui-pdp-qadb';
const FEATURE_NAME = 'qadb';

const RenderQADBComponents = (
  component,
  props,
  onCreateQuestion,
  onChangeQuestion,
  onLeaveFeedback,
  onCancelSearchQuery,
  onClickHowToMakeQuestionButton,
  onCloseSmartAnswerModal,
  onOpenQuestionsFromSmartAnswer,
  smartAnswer,
  smartAnswerModal,
  makequestion,
  questionModal,
  parameters,
  app,
) => {
  const { id, state, query } = component;
  if (state === 'VISIBLE') {
    switch (id) {
      case QUESTIONS:
        return (
          <QuestionsList
            key="question-list"
            {...component}
            {...props}
            onClickHowToMakeQuestionButton={onClickHowToMakeQuestionButton}
          />
        );
      case OWNER_QUESTIONS:
        return (
          <QuestionsList
            key="owner-question-list"
            {...component}
            {...props}
            isOwner
            onClickHowToMakeQuestionButton={onClickHowToMakeQuestionButton}
          />
        );
      case NO_LAST_QUESTIONS:
        return <NoLastQuestions {...component} {...props} />;
      case FEEDBACK:
        return <Feedback key="feedback" {...component} {...props} />;
      case MAKE_QUESTION_MODAL:
        return (
          <QuestionModal
            key="question-modal"
            {...questionModal}
            {...props}
            query={query}
            onCreateQuestion={onCreateQuestion}
            app={app}
            parameters={parameters}
            onCancelSearchQuery={onCancelSearchQuery}
          />
        );
      case SMART_ANSWER_MODAL:
        return (
          <SmartAnswerModal
            key={`${Math.random()}-smart-answer-modal`}
            onCreateQuestion={onCreateQuestion}
            app={app}
            onCancelSearchQuery={onCancelSearchQuery}
            onCloseSmartAnswerModal={onCloseSmartAnswerModal}
            smartAnswerModal={smartAnswerModal}
            onOpenQuestionsFromSmartAnswer={onOpenQuestionsFromSmartAnswer}
            parameters={parameters}
            {...smartAnswer}
            {...props}
          />
        );
      case MAKE_QUESTION:
        return (
          <MakeQuestion
            key="make-question-"
            {...component}
            {...props}
            disabled={makequestion.disabled}
            app={app}
            parameters={parameters}
            onCreateQuestion={onCreateQuestion}
          />
        );
      case MAKE_QUESTION_SA:
        return (
          <MakeQuestionSA
            key="make-question-sa"
            {...component}
            {...props}
            disabled={makequestion.disabled}
            app={app}
            parameters={parameters}
            onCreateQuestion={onCreateQuestion}
            onChangeQuestion={onChangeQuestion}
          />
        );
      case CARD_ANSWER:
        return <CardAnswer key="card-answer" {...component} {...props} onLeaveFeedback={onLeaveFeedback} />;
      case PUSH_NOTIFICATION_QUESTION_MODAL:
        return <OnBoardingMobile {...component} />;
      case CALL_TO_ACTION:
        return (
          <HowToMakeQuestionButton
            key="how-to-make-question"
            {...component}
            {...props}
            onClickHowToMakeQuestionButton={onClickHowToMakeQuestionButton}
          />
        );
      case RESULTS: {
        const { components } = component;

        return (
          components &&
          components.length > 0 &&
          components.map(innerComponent =>
            RenderQADBComponents(
              innerComponent,
              { ...props },
              onCreateQuestion,
              onChangeQuestion,
              onLeaveFeedback,
              onCancelSearchQuery,
              onClickHowToMakeQuestionButton,
              onCloseSmartAnswerModal,
              onOpenQuestionsFromSmartAnswer,
              smartAnswer,
              smartAnswerModal,
              makequestion,
              questionModal,
              parameters,
              app,
            ),
          )
        );
      }
      default:
        return null;
    }
  } else {
    return null;
  }
};

const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop - 10);

const QADB = ({
  app,
  className,
  title,
  product_id,
  item_id,
  components,
  quickActions,
  searchBar,
  isFetching,
  query,
  track_view, // Initial track for when the component is on viewport
  snackMessage,
  smartAnswerModal,
  smartAnswer,
  makequestion,
  parameters,
  questionModal,
  onCreateQuestion,
  onChangeQuestion,
  onSearchQuery,
  onCancelSearchQuery,
  onLeaveFeedback,
  onSearchBlur,
  onSearchFocus,
  onClickHowToMakeQuestionButton,
  onCloseHowToMakeQuestionTooltip,
  onCloseSmartAnswerModal,
  onOpenQuestionsFromSmartAnswer,
  custom_message,
  showVariationsError,
}) => {
  // Thresholds are defined to know when te component is usable
  const refView = useTrackView(track_view, { threshold: 0.45 });
  const { deviceType, flashMessage } = useContext(StaticPropsContext);
  // -------------------------------------
  // ----- BEGIN GUEST QUESTON LOGIC -----
  // -------------------------------------
  const [activeFlashMessage, setActiveFlashMessage] = useState(flashMessage);
  if (activeFlashMessage) {
    const questionsAsk =
      components && components.length > 0 && components.find(component => component.id === MAKE_QUESTION_MODAL);

    if (questionsAsk && flashMessage.success === true) {
      trackEvent(questionsAsk.action.success.track);
    } else if (flashMessage.success === false) {
      trackEvent(questionsAsk.action.failure.track);
    }

    setActiveFlashMessage(null);
  }
  // -----------------------------------
  // ----- END GUEST QUESTON LOGIC -----
  // -----------------------------------

  const handleSearchQuery = props => {
    scrollToRef(refView);
    onSearchQuery(props);
  };

  return (
    <div ref={refView} className={classnames(namespace, className)} id={FEATURE_NAME}>
      <h2 className={`${namespace}__title`}>{title}</h2>
      {custom_message && (
        <QuestionWarningMessage customMessage={custom_message} showVariationsError={showVariationsError} />
      )}
      {quickActions && <QuestionsQuickAccess {...quickActions} deviceType={deviceType} />}
      {searchBar && searchBar.state !== 'HIDDEN' && (
        <SearchBar
          {...searchBar}
          onSearchBlur={onSearchBlur}
          onSearchFocus={onSearchFocus}
          product_id={product_id}
          item_id={item_id}
          onSearchQuery={handleSearchQuery}
          onCancelSearchQuery={onCancelSearchQuery}
          shouldBlurOnSubmit={deviceType === 'mobile'}
          onCloseHowToMakeQuestionTooltip={onCloseHowToMakeQuestionTooltip}
        />
      )}
      <>
        {isFetching && <Loader />}
        {components &&
          components.length > 0 &&
          components.map(component =>
            RenderQADBComponents(
              component,
              { product_id, item_id, query },
              onCreateQuestion,
              onChangeQuestion,
              onLeaveFeedback,
              onCancelSearchQuery,
              onClickHowToMakeQuestionButton,
              onCloseSmartAnswerModal,
              onOpenQuestionsFromSmartAnswer,
              smartAnswer,
              smartAnswerModal,
              makequestion,
              questionModal,
              parameters,
              app,
            ),
          )}
        {snackMessage && <Snackbar {...snackMessage} />}
      </>
    </div>
  );
};

QADB.propTypes = {
  app: string.isRequired,
  className: string,
  title: string.isRequired,
  product_id: string,
  item_id: string.isRequired,
  isFetching: bool,
  components: arrayOf(shape()),
  quickActions: shape({}),
  parameters: shape({}),
  searchBar: shape({}).isRequired,
  snackMessage: shape({}),
  query: string,
  track_view: shape({}).isRequired,
  onSearchQuery: func.isRequired,
  onCreateQuestion: func.isRequired,
  onChangeQuestion: func.isRequired,
  onLeaveFeedback: func.isRequired,
  onCancelSearchQuery: func.isRequired,
  onSearchBlur: func.isRequired,
  onSearchFocus: func.isRequired,
  onClickHowToMakeQuestionButton: func,
  onCloseHowToMakeQuestionTooltip: func,
  onCloseSmartAnswerModal: func,
  onOpenQuestionsFromSmartAnswer: func,
  smartAnswer: shape({}),
  smartAnswerModal: shape({}),
  questionModal: shape({}),
  makequestion: shape({}),
  custom_message: shape({}),
  showVariationsError: func,
};

QADB.defaultProps = {
  className: null,
  isFetching: false,
  snackMessage: null,
  components: null,
  query: '',
  quickActions: null,
  onClickHowToMakeQuestionButton: null,
  onCloseHowToMakeQuestionTooltip: null,
  onCloseSmartAnswerModal: null,
  onOpenQuestionsFromSmartAnswer: null,
  smartAnswer: null,
  smartAnswerModal: null,
  questionModal: null,
  makequestion: null,
  parameters: null,
  custom_message: null,
  showVariationsError: () => {},
};

export default QADB;
