import React, { useEffect } from 'react';
import { string, shape } from 'prop-types';
import StyledLabel from '../../../styled-label';
import Snackbar from '../snackbar';
import { trackEvent } from '../../../../lib/tracking';

const namespace = 'ui-pdp-qadb__feedback';

const Feedback = ({ text, track, success_message }) => {
  useEffect(() => {
    trackEvent(track);
  }, [track]);

  return (
    <div className={namespace}>
      <StyledLabel
        className={`${namespace}__label`}
        as="p"
        font_size="LARGE"
        color="BLACK"
        font_family="REGULAR"
        withDangerous
        text={text}
      />
      <Snackbar message={success_message} color="green" show />
    </div>
  );
};

Feedback.propTypes = {
  text: string.isRequired,
  success_message: string.isRequired,
  track: shape({}).isRequired,
};

export default Feedback;
