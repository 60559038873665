import React from 'react';
import { bool, string } from 'prop-types';
import Snackbar from '@andes/snackbar';
import { validateSnackbarColor } from '../../../../utils/validators';

const SnackBarError = ({ key, message, show, type }) => (
  <Snackbar show={show} message={message} delay={2000} color={validateSnackbarColor(type)} key={key} />
);

SnackBarError.propTypes = {
  key: string,
  message: string.isRequired,
  show: bool,
  type: string,
};

SnackBarError.defaultProps = {
  key: null,
  show: false,
  type: 'default',
};

export default React.memo(SnackBarError);
