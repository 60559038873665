import React, { useState, useEffect, useRef, useContext } from 'react';
import { string, arrayOf, shape, bool } from 'prop-types';
import classnames from 'classnames';
import Modal from '@andes/modal';
import ComponentList from '../component-list';
import StaticPropsContext from '../context/static-props';
import { hyphenatedbem } from '../../lib/hyphenatedbem';
import { trackEvent } from '../../lib/tracking';
import withMappedProps from '../../utils/withMappedProps';
import useViewCounter from '../../hooks/use-view-counter';
import { usePushNotificationsPermission, blockPushNotifications } from '../../hooks/use-push-notifications-permission';
import {
  isPushNotificationsPermissionModal,
  PUSH_NOTIFICATION_DECLINE_ACTION,
} from '../../lib/push-notifications-permission';

// AvailableComponents
import ImageComponent from '../image/image-component';
import { imageToProps } from '../image/image-to-props';
import StyledLabel from '../styled-label/index';
import labelToProps from '../styled-label/label-to-props';
import Separator from '../grid/separator';
import { emptySeparatorToProps } from '../grid/separator/jsonToProps';
import Media from '../media/media';
import { mediaToProps } from '../media/media-to-props';
import Actions from '../buybox/actions/actions';
import OnboardingHeader from './components/header';
import { headerToProps } from './components/header/jsonToProps';
import BoxList from '../box-list/box-list';

const namespace = 'onboarding';

const availableComponents = {};
availableComponents.modal_header = withMappedProps(OnboardingHeader)(headerToProps);
availableComponents.image_component = withMappedProps(ImageComponent)(imageToProps);
availableComponents.label_component = withMappedProps(StyledLabel)(labelToProps);
availableComponents.separator = withMappedProps(Separator)(emptySeparatorToProps);
availableComponents.generic_info_row = withMappedProps(Media)(mediaToProps);
availableComponents.label_list_component = BoxList;

const Onboarding = ({ id, components, track, storable, state, closable, ariaLabel }) => {
  const { siteId } = useContext(StaticPropsContext);
  const [isVisible, setIsVisible] = useState(state !== 'TRACK_HIDDEN');
  const initialState = { components };
  const modalRef = useRef(null);
  const updateCloseStatus = useViewCounter({
    id,
    visible: state === 'TRACK_HIDDEN' || state === 'VISIBLE' || isVisible,
    storable,
    cookieName: 'modal-configuration',
  });

  useEffect(() => {
    if (state === 'VISIBLE') {
      setIsVisible(true);
    }
  }, [state]);

  const canAskForPermission = usePushNotificationsPermission();

  if (isPushNotificationsPermissionModal(id) && !canAskForPermission) {
    return null;
  }

  const handleClose = (e, action) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (track) {
      trackEvent(track);
    } // Tracks on close/print for most onboardings

    if (action && action.target) {
      if (!closable) {
        setIsVisible(false);
      }
      window.location.href = action.target;
    } else {
      if (storable) {
        updateCloseStatus();
      }

      if (isPushNotificationsPermissionModal(id) && action && action.id === PUSH_NOTIFICATION_DECLINE_ACTION) {
        blockPushNotifications();
      }
    }

    setIsVisible(false);
  };

  const actionsToProps = props => ({
    ...props,
    actions: props.actions.map(action => ({ ...action, onClick: e => handleClose(e, action) })),
  });

  availableComponents.modal_actions = withMappedProps(Actions)(actionsToProps);

  return (
    <div key={id} className={namespace}>
      <Modal
        ref={modalRef}
        className={classnames(`${namespace}__modal`, hyphenatedbem(id), {
          [`cbt-modal--short`]: id === 'cbt_modal' && siteId === 'MLB',
        })}
        open={isVisible}
        type="card"
        srLabel={ariaLabel}
      >
        <form className={`${namespace}__container`}>
          <ComponentList availableComponents={availableComponents} initialState={initialState} hasContainerTop />
        </form>
      </Modal>
    </div>
  );
};

Onboarding.propTypes = {
  id: string.isRequired,
  components: arrayOf(shape({})).isRequired,
  track: shape({}),
  storable: bool,
  state: string,
  actions: arrayOf(shape({})).isRequired,
  closable: bool.isRequired,
  ariaLabel: string,
};

Onboarding.defaultProps = {
  track: null,
  storable: true,
  state: '',
  ariaLabel: '',
};

export default React.memo(Onboarding);
