import React from 'react';
import { string, shape, func } from 'prop-types';
import { Button } from '@andes/button';
import StyledLabel from '../../../styled-label';
import { trackEvent } from '../../../../lib/tracking';

const namespace = 'ui-pdp-qadb__find-in-questions';

const FindInQuestions = ({ action, tooltip, onClickSeeMoreAnswersButton }) => {
  const handleClick = () => {
    onClickSeeMoreAnswersButton({ tooltip });
    trackEvent(action.track);
  };

  return (
    <Button
      data-testid="find-in-questions"
      className={`${namespace}__button`}
      onClick={handleClick}
      hierarchy="transparent"
    >
      <StyledLabel
        className={`${namespace}__button-text`}
        as="p"
        color="BLUE"
        font_family="SEMIBOLD"
        font_size="SMALL"
        text={action.label.text}
      />
    </Button>
  );
};

FindInQuestions.propTypes = {
  action: shape({
    label: shape({ text: string.isRequired }).isRequired,
    track: shape({ melidata_event: shape({}).isRequired }).isRequired,
  }).isRequired,
  tooltip: shape({ title: shape({ text: string }), body: shape({ text: string }) }).isRequired,
  onClickSeeMoreAnswersButton: func.isRequired,
};

FindInQuestions.defaultProps = {};

export default FindInQuestions;
