import React, { Fragment, useState, useContext, useEffect } from 'react';
import { string, arrayOf, shape, number, func, bool } from 'prop-types';
import { Button } from '@andes/button';
import classnames from 'classnames';
import StyledLabel from '../../../styled-label';
import IconResponse from '../../../icons/response';
import IconChevron from '../../../icons/chevron';
import FindInQuestions from '../find-in-questions';
import QuestionTextCollapse from '../../../questions/questions-text-collapse';
import ActionModal from '../../../action-modal/action-modal';
import Iframe from '../../../iframe/iframe';
import ActionRow from '../../../action-row';
import StaticPropsContext from '../../../context/static-props';
import onIframeModalResize from '../../../../lib/on-iframe-modal-resize';
import { getHighlightedText } from '../../lib';
import { trackEvent } from '../../../../lib/tracking';
import { generateKeyToComponent } from '../../../../utils/validators';

const namespace = 'ui-pdp-qadb__questions-list';

const QuestionsListAnswerItem = ({
  text,
  answered_by_text,
  highlights,
  denounce,
  answer_date_formatted,
  denounce_template,
  tapeableLinks,
}) => (
  <div>
    <QuestionTextCollapse
      className="answer"
      length={text.length}
      component="qadb"
      text={text}
      highlights={highlights}
      tapeableLinks={tapeableLinks}
    />

    {answered_by_text && (
      <StyledLabel
        className={`${namespace}__answer-item__answered-by`}
        as="p"
        font_family="REGULAR"
        font_size="XSMALL"
        color="GRAY"
        text={answered_by_text}
      />
    )}

    {answer_date_formatted && (
      <StyledLabel
        className={`${namespace}__question-item__separate`}
        font_size="XSMALL"
        font_family="REGULAR"
        as="span"
        text={answer_date_formatted}
      />
    )}

    {denounce && (
      <StyledLabel
        className={classnames(`${namespace}__answer-item__denounce`)}
        as="a"
        font_size="XSMALL"
        font_family="REGULAR"
        href={denounce.target}
        target="_blank"
        text={denounce_template && denounce_template.answer && denounce_template.answer.text}
      />
    )}
  </div>
);

QuestionsListAnswerItem.propTypes = {
  text: string.isRequired,
  answer_date_formatted: string,
  denounce_template: shape(),
  denounce: shape({
    target: string,
  }),
  answered_by_text: string.isRequired,
  highlights: arrayOf(shape({})),
  tapeableLinks: shape(),
};

QuestionsListAnswerItem.defaultProps = {
  highlights: null,
  denounce: null,
  denounce_template: null,
};

const Question = ({
  index,
  question_id,
  answers,
  question: { text, highlights },
  denounce,
  denounce_template,
  tapeableLinks,
}) => (
  <div data-testid={`question-${question_id}`} key={question_id} className={`${namespace}__question`}>
    <div className={`${namespace}__question__container`} id={`question-${index}`} tabIndex="-1">
      <StyledLabel
        as="span"
        className={`${namespace}__question__label`}
        font_family="REGULAR"
        color="BLACK"
        font_size="SMALL"
        text={getHighlightedText(text, highlights)}
      />

      {denounce && (
        <StyledLabel
          className={classnames(`${namespace}__question-item__denounce`)}
          as="a"
          font_size="XSMALL"
          font_family="REGULAR"
          href={denounce.target}
          target="_blank"
          text={denounce_template && denounce_template.question && denounce_template.question.text}
        />
      )}
    </div>
    <div className={`${namespace}__question__answer-container`}>
      {answers &&
        answers.map(answer => (
          <Fragment key={generateKeyToComponent(answer)}>
            <IconResponse className={`${namespace}__question__answer-container__icon`} />
            <QuestionsListAnswerItem
              className={`${namespace}__question__answer-container__answer`}
              {...answer}
              denounce_template={denounce_template}
              tapeableLinks={tapeableLinks}
            />
          </Fragment>
        ))}
    </div>
  </div>
);

Question.propTypes = {
  index: number.isRequired,
  text: string,
  question_id: number.isRequired,
  answered_by_text: string,
  question: shape({ text: string, highlights: arrayOf(shape()) }),
  denounce_template: shape(),
  answers: arrayOf(shape()),
  denounce: shape({
    target: string,
  }),
  highlights: arrayOf(
    shape({
      start_at: number,
      characters_amount: number,
    }),
  ),
  tapeableLinks: shape(),
};

Question.defaultProps = {
  highlights: null,
  question: null,
  answers: null,
  denounce: null,
  denounce_template: null,
};

const QuestionsList = ({
  className,
  title,
  questions,
  see_more_limit,
  see_more_text,
  denounce_template,
  isOwner,
  show_more,
  track,
  search_all_action,
  onClickHowToMakeQuestionButton,
  close_modal_label: closeModalLabel,
  touchable_links: tapeableLinks,
}) => {
  const { lowEnd, deviceType } = useContext(StaticPropsContext);
  const [seeMore, setSeeMore] = useState(false);

  useEffect(() => {
    if (seeMore) {
      document.querySelector(`#question-${see_more_limit}`).focus();
    }
  }, [seeMore, see_more_limit]);

  const handleSeeMore = e => {
    e.preventDefault();
    setSeeMore(!seeMore);
    trackEvent(track);
  };

  return (
    <div className={classnames(className, namespace)}>
      <StyledLabel
        as="h3"
        className={`${namespace}__title`}
        font_family="SEMIBOLD"
        font_size="MEDIUM"
        text={title}
        color="BLACK"
      />

      <div className={isOwner ? `${namespace}__wraper-owner` : `${namespace}__wraper`}>
        {questions &&
          questions.map((question, index) => {
            // Si está habilitado el see more
            // Si es lowend
            // Si no viene el límite de backend
            // Si el index no supera el límite
            // --> Hago render de la pregunta
            if (seeMore || lowEnd || !see_more_limit || index < see_more_limit) {
              return (
                <Question
                  key={String(question?.question_id || index)}
                  index={index}
                  denounce_template={denounce_template}
                  tapeableLinks={tapeableLinks}
                  {...question}
                />
              );
            }
            return null;
          })}

        {seeMore && search_all_action && see_more_text.accesibility_text && (
          <span className="ui-pdp--hide" aria-live="assertive">
            {see_more_text.accesibility_text}
          </span>
        )}
        {!lowEnd && see_more_text.text && !seeMore && !show_more && (
          <Button
            data-testid="qadb-see-more"
            className={`${namespace}__see-more`}
            onClick={handleSeeMore}
            hierarchy="transparent"
          >
            <StyledLabel
              className={`${namespace}__see-more__link`}
              as="p"
              color="BLUE"
              font_family="SEMIBOLD"
              font_size="SMALL"
              text={see_more_text.text}
            />
            <IconChevron
              aria-hidden="true"
              className={classnames(`${namespace}__chevron ui-pdp-icon--chevron-down`, {
                'ui-pdp-icon--chevron-up--active': seeMore,
              })}
            />
          </Button>
        )}

        {show_more && show_more.target && deviceType === 'desktop' && (
          <ActionModal
            className={`${namespace}__see-more__modal`}
            modalClassName={classnames(`${namespace}__see-more__modal-content`)}
            modalUrl="#questions-modal"
            url={show_more.target}
            label={show_more.label}
            modalTitle={title}
            autoHeight={false}
            type={deviceType}
            controlModalHeader
            closeModalLabel={closeModalLabel}
          >
            <Iframe
              className={namespace}
              src={`${show_more.target}&modal=true`}
              title={null}
              scrolling="no"
              onMessage={onIframeModalResize(
                'vip:modal-content:loaded',
                'height',
                '.ui-pdp-iframe-modal',
                '.andes-modal__header',
              )}
            />
          </ActionModal>
        )}

        {show_more && show_more.label && deviceType === 'mobile' && (
          <ActionRow
            className={`${namespace}__action`}
            linkClassName="mt-20"
            label={show_more.label.text}
            url={show_more.target}
            modifier="secondary"
          />
        )}

        {seeMore && search_all_action && (
          <FindInQuestions
            tooltip={search_all_action.tooltip}
            action={search_all_action.action}
            onClickSeeMoreAnswersButton={onClickHowToMakeQuestionButton}
          />
        )}
      </div>
    </div>
  );
};

QuestionsList.propTypes = {
  className: string,
  isOwner: bool,
  denounce_template: shape({}),
  denounce: shape({
    target: string,
  }),
  see_more_limit: number,
  see_more_text: shape({
    text: string,
  }),
  show_more: shape(),
  search_all_action: shape({}),
  onClickHowToMakeQuestionButton: func.isRequired,
  track: shape({}),
  questions: arrayOf(shape({})).isRequired,
  title: string.isRequired,
  close_modal_label: string,
  touchable_links: shape(),
};

QuestionsList.defaultProps = {
  className: null,
  denounce: null,
  isOwner: false,
  denounce_template: { question: { text: 'Denunciar' }, answer: { text: 'Denunciar' } },
  see_more_limit: null,
  see_more_text: {
    text: null,
  },
  show_more: null,
  search_all_action: null,
  track: null,
};

export default QuestionsList;
