import React from 'react';
import { string, shape } from 'prop-types';
import classnames from 'classnames';
import StyledLabel from '../../../styled-label';
import ImageComponent from '../../../image/image-component';
import { hyphenatedbem } from '../../../../lib/hyphenatedbem';

const namespace = 'ui-vpp-onboarding-header';

const OnboardingHeader = ({ id, title, image }) => (
  <div className={classnames(namespace, id)}>
    <StyledLabel {...title} />
    <ImageComponent className={hyphenatedbem(id)} {...image} />
  </div>
);

OnboardingHeader.propTypes = {
  id: string,
  title: string,
  image: shape({}),
};

OnboardingHeader.defaultProps = {
  id: null,
  title: null,
  image: null,
};

export default OnboardingHeader;
