import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';

const ICON_ID = 'help';
const namespace = 'ui-pdp-icon ui-pdp-icon--help';

const IconHelp = ({ className }) => (
  <svg className={classnames(namespace, className)} xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 36 34">
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconHelp.propTypes = {
  className: string,
};

IconHelp.defaultProps = {
  className: null,
};

IconHelp.ICON_ID = ICON_ID;

export default React.memo(IconHelp);
