import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import onIframeModalResize from '../../../../lib/on-iframe-modal-resize';
import QuestionsQuickAccessMobile from '../../../questions/questions-quick-access.mobile';
import QuestionsQuickAccessDesktop from '../../../questions/questions-quick-access.desktop';

const namespace = 'ui-pdp-quick-access';

const RenderQuickAccessDevice = ({ quick_access, deviceType }) => {
  switch (deviceType) {
    case 'mobile':
      return (
        <QuestionsQuickAccessMobile
          {...quick_access}
          onIframeMessage={onIframeModalResize(
            'vip:modal-content:loaded',
            'height',
            '.ui-pdp-iframe-modal',
            '.andes-modal__header',
            null,
            deviceType,
          )}
        />
      );
    case 'desktop':
      return (
        <QuestionsQuickAccessDesktop
          {...quick_access}
          onIframeMessage={onIframeModalResize(
            'vip:modal-content:loaded',
            'height',
            '.ui-pdp-iframe-modal',
            '.andes-modal__header',
            'width',
            deviceType,
          )}
        />
      );
    default:
      return null;
  }
};

RenderQuickAccessDevice.propTypes = {
  quick_access: shape({
    actions: arrayOf(
      shape({
        label: shape({
          text: string.isRequired,
        }),
        target: string.isRequired,
      }),
    ),
    title: shape({ text: string.isRequired }).isRequired,
  }).isRequired,
  deviceType: string,
};

RenderQuickAccessDevice.defaultProps = {
  deviceType: null,
};

const QuestionsQuickAccess = ({ quick_access, deviceType }) => (
  <div className={`${namespace}__container`}>
    <RenderQuickAccessDevice quick_access={quick_access} deviceType={deviceType} />
  </div>
);

QuestionsQuickAccess.propTypes = {
  quick_access: shape({
    actions: arrayOf(
      shape({
        label: shape({
          text: string.isRequired,
        }),
        target: string.isRequired,
      }),
    ),
    title: shape({ text: string.isRequired }).isRequired,
  }).isRequired,
  deviceType: string,
};

QuestionsQuickAccess.defaultProps = {
  deviceType: null,
};

export default QuestionsQuickAccess;
