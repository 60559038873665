import trackNewrelic from '../services/client/new-relic.client';

const usePushNotificationsPermission = () => {
  try {
    return (
      'serviceWorker' in navigator &&
      'PushManager' in window &&
      'showNotification' in ServiceWorkerRegistration.prototype &&
      localStorage.getItem(`push-notification-blocked`) === null
    );
  } catch (e) {
    return false;
  }
};

const blockPushNotifications = () => {
  try {
    localStorage.setItem(`push-notification-blocked`, true);
  } catch (e) {
    trackNewrelic(e);
  }
};

export { usePushNotificationsPermission, blockPushNotifications };
