import React from 'react';
import { string, arrayOf, shape, oneOfType, node, func } from 'prop-types';
import { Label } from '../../prop-types';
import BoxListItem from './box-list-item';
import IconFactory from '../icons/factory-builder';

const namespace = 'ui-vpp-box-list';

const BoxList = ({ content_rows }) => (
  <ul className={namespace}>
    {content_rows.map(value => (
      <BoxListItem
        className={`${namespace}__label`}
        key={(value.label || value.title).text}
        figure={IconFactory(value.icon)}
        {...value}
      />
    ))}
  </ul>
);

BoxList.propTypes = {
  content_rows: arrayOf(
    shape({
      label: Label,
      title: Label,
      icon: string,
      figure: oneOfType([node, func]),
    }),
  ).isRequired,
};

export default BoxList;
