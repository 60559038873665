import React from 'react';
import { shape, arrayOf, func } from 'prop-types';
import SmartAnswer from '../smart-answer';

const namespace = 'ui-pdp-qadb__card-answer';

const CardAnswer = ({ smart_answers, onLeaveFeedback }) => (
  <div className={namespace}>
    {smart_answers && smart_answers.length > 0 && (
      <div className={`${namespace}__smart-answers`}>
        {smart_answers.map((smart_answer, index) => (
          <SmartAnswer key={smart_answer?.id || index} onLeaveFeedback={onLeaveFeedback} {...smart_answer} />
        ))}
      </div>
    )}
  </div>
);

CardAnswer.propTypes = {
  smart_answers: arrayOf(shape({})).isRequired,
  onLeaveFeedback: func.isRequired,
};

export default React.memo(CardAnswer);
