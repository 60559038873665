import React, { useState, useContext } from 'react';
import { string, shape, func, bool } from 'prop-types';
import { Button } from '@andes/button';
import StaticPropsContext from '../../../context/static-props';
import StyledLabel from '../../../styled-label';
import BaseModal from '../../../modals/base-modal';
import QuestionForm from '../question-form/question-form';
import { trackEvent } from '../../../../lib/tracking';

const namespace = 'ui-pdp-qadb__make-question';

const MakeQuestion = ({ title, action, query, disabled, onCreateQuestion, app, parameters, id, closeModalLabel }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { deviceType } = useContext(StaticPropsContext);
  const type = deviceType === 'mobile' ? 'card' : 'large';

  const handleMakeQuestion = ({ item_id, question, failure, success }) => {
    onCreateQuestion({ item_id, question, failure, success, app, parameters });
    setIsOpen(false);
  };

  const handleClose = e => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setIsOpen(false);
  };

  const handleOpenModal = e => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setIsOpen(true);
    trackEvent(action.track);
  };

  return (
    <div className={namespace}>
      <StyledLabel className={`${namespace}__label`} font_size="XSMALL" as="p" withDangerous text={title} />
      <Button
        data-testid="make-question-cta"
        className={`${namespace}__button`}
        hierarchy="loud"
        disabled={disabled}
        onClick={handleOpenModal}
      >
        {action.text}
      </Button>
      <BaseModal
        key={`${namespace}__modal-${Math.random()}`}
        id={`${namespace}__modal`}
        isOpen={isOpen}
        onClose={handleClose}
        closable
        closeModalLabel={closeModalLabel}
        title={action.form.title}
        type={type}
        autoHeight={false}
        maxWidth={640}
      >
        <QuestionForm
          onCreateQuestion={handleMakeQuestion}
          onCancelSearchQuery={handleClose}
          initialValue={query}
          item_id={action.item_id}
          disabled={disabled}
          action={action}
          id={id}
        />
      </BaseModal>
    </div>
  );
};

MakeQuestion.propTypes = {
  app: string.isRequired,
  id: string.isRequired,
  title: string.isRequired,
  action: shape({
    text: string.isRequired,
    form: shape({
      title: string,
    }).isRequired,
  }).isRequired,
  query: string,
  disabled: bool,
  onCreateQuestion: func.isRequired,
  parameters: shape({}),
  closeModalLabel: string.isRequired,
};

MakeQuestion.defaultProps = {
  query: '',
  disabled: false,
  parameters: null,
};

export default MakeQuestion;
